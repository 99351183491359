import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IrlRoutine, IrlRoutineCurrentSet, Routine } from '../types/routines';
import { api } from './api';

export interface RoutinesSlice {
  irlCurrent: IrlRoutine | null;
  irlNext: IrlRoutine | null;
  irlPrevious: IrlRoutine | null;
  irlHeelsCurrent: IrlRoutine | null;
  irlHeelsNext: IrlRoutine | null;
  irlHeelsPrevious: IrlRoutine | null;
  exclusive: Routine[] | null;
  archive: Routine[] | null;
}

// Routines slice stores the last retrieved routines data
// Populated as required/can be partially populated, e.g. if current set is required on first page but archive isn't
export const initialState: RoutinesSlice = {
  irlCurrent: null,
  irlNext: null,
  irlPrevious: null,
  irlHeelsCurrent: null,
  irlHeelsNext: null,
  irlHeelsPrevious: null,
  exclusive: null,
  archive: null,
};

const slice = createSlice({
  name: 'routines',
  initialState: initialState,
  reducers: {
    clearRoutines(state) {
      return initialState;
    },
    updateRoutines(state, action: PayloadAction<Partial<RoutinesSlice>>) {
      return Object.assign({}, state, action.payload);
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getRoutinesCurrentSet.matchFulfilled, (state, { payload }) => {
      const currentSet = payload as IrlRoutineCurrentSet;
      state.irlCurrent = currentSet.current || null;
      state.irlNext = currentSet.next || null;
      state.irlPrevious = currentSet.previous || null;
      return state;
    });
    builder.addMatcher(
      api.endpoints.getHeelsRoutinesCurrentSet.matchFulfilled,
      (state, { payload }) => {
        const currentSet = payload as IrlRoutineCurrentSet;
        state.irlHeelsCurrent = currentSet.current || null;
        state.irlHeelsNext = currentSet.next || null;
        state.irlHeelsPrevious = currentSet.previous || null;
        return state;
      },
    );
    builder.addMatcher(api.endpoints.getRoutinesExclusive.matchFulfilled, (state, { payload }) => {
      const exclusiveList = payload as Routine[];
      state.exclusive = exclusiveList;
      return state;
    });
    builder.addMatcher(api.endpoints.getRoutinesArchive.matchFulfilled, (state, { payload }) => {
      const archiveList = payload as Routine[];
      state.archive = archiveList;
      return state;
    });
  },
});

const { actions, reducer } = slice;
export const { clearRoutines, updateRoutines } = actions;
export default reducer;
