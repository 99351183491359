import moment from 'moment';
import { EventResponse } from '../types/events';
import { capitalizeFirstLetter } from './string';

export function formatEventDuration(durationMins: number) {
  const hours = Math.floor(durationMins / 60);
  const mins = durationMins % 60;

  return `${hours === 1 ? `1 hour` : hours > 1 ? `${hours} hours` : ''}${
    mins > 0 ? ` ${mins} mins` : ''
  }`;
}

export function getEventDetailsString(event: EventResponse) {
  const startDate = new Date(event.startDateTime);

  const startTime = moment(startDate).format('h:mma');
  const endDateTime = moment(startDate).add(event.duration, 'm').toDate();
  const endTime = moment(endDateTime).format('h:mma');

  return `${capitalizeFirstLetter(event.type)} · ${startTime} - ${endTime} · ${formatEventDuration(
    event.duration,
  )} · £${event.ticketPrice?.toFixed(2)}`;
}
