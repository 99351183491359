import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Spinner,
  Tab,
  Tabs,
  Tag,
  TagLabel,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { ACTION_TYPE, EVENT_STATUS } from '../../constants/enums';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import {
  useCancelEventMutation,
  useGetEventTicketsQuery,
  useRemoveQueenFromEventMutation,
} from '../../store/api';
import { clearEventSlice, setTicketToCancelled } from '../../store/eventSlice';
import { setEventCancelled, setTicketToCancelledList } from '../../store/eventsSlice';
import { clearModalsSlice } from '../../store/modalsSlice';
import { EventResponse } from '../../types/events';
import { TicketResponse } from '../../types/tickets';
import { getEventDetailsString } from '../../utils/event';
import EventAttendeeCard from '../cards/EventAttendeeCard';
import ConfirmAction from './ConfirmAction';

export default function EventView() {
  const { event } = useTypedSelector((state) => state);

  const [cancelClassClicked, setCancelClassClicked] = useState<boolean>(false);
  const [queenToRemove, setQueenToRemove] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [cancelEvent] = useCancelEventMutation();
  const [removeQueen] = useRemoveQueenFromEventMutation();

  const toast = useToast();

  const dispatch: any = useAppDispatch();

  const t = useTranslations('BossHub.viewEvent');

  const startDate = new Date(event.startDateTime || '');
  const formattedDate = moment(startDate).format('dddd Do MMMM');

  const { data, isFetching, isLoading, isError } = useGetEventTicketsQuery(
    event.id ? { eventId: event.id } : skipToken,
  );

  const ticketsLoaded = data && !isLoading && !isFetching;

  function onClickCancelClass() {
    setCancelClassClicked(true);
  }

  async function confirmCancelClass(message?: string) {
    const response = await cancelEvent({ eventId: event.id || '', message: message || '' });

    if ('error' in response) {
      setError('Failed to cancel class. Call admin if the problem persists.');
      setCancelClassClicked(false);
      return <EventView />;
    } else {
      dispatch(setEventCancelled({ eventId: event.id || '' }));
      toast({
        title: 'Event cancelled',
        status: 'success',
        isClosable: true,
      });
    }

    dispatch(clearModalsSlice());
    dispatch(clearEventSlice());
  }

  async function confirmRemoveQueen(message?: string) {
    if (event.id && queenToRemove) {
      const response = await removeQueen({
        eventId: event.id,
        ticketId: queenToRemove,
        message: message,
      });

      if ('error' in response) {
        setError('Failed to remove queen. Call admin if the problem persists.');
      } else {
        dispatch(setTicketToCancelled({ ticketId: queenToRemove }));
        dispatch(setTicketToCancelledList({ eventId: event.id, ticketId: queenToRemove }));
        toast({
          title: 'Queen removed from Event',
          status: 'success',
          isClosable: true,
        });
      }
    } else {
      setError('Failed to remove queen. Call admin if the problem persists.');
    }

    setQueenToRemove(null);
    return <EventView />;
  }

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      {cancelClassClicked ? (
        <ConfirmAction actionName={ACTION_TYPE.CANCEL_EVENT} confirmAction={confirmCancelClass} />
      ) : queenToRemove ? (
        <ConfirmAction
          actionName={ACTION_TYPE.REMOVE_QUEEN_FROM_CLASS}
          confirmAction={confirmRemoveQueen}
        />
      ) : (
        <>
          <Box
            w="100%"
            mt={{ base: '3rem', lg: '3.5rem' }}
            pt="1rem"
            px={{ base: '1.25rem', lg: '4rem' }}
            data-test-id="view-event-box"
          >
            <Flex direction="row">
              <Flex direction="column">
                <Tag
                  size="sm"
                  maxW="90%"
                  overflowWrap="anywhere"
                  bgColor="pink.500"
                  textColor="white"
                  w="fit-content"
                  mb="1.313rem"
                  textTransform="capitalize"
                  data-test-id="routine-details-tag"
                >
                  <TagLabel>
                    {`${event.licenseType}: ${event.routine?.artist} - ${event.routine?.songName}`}
                  </TagLabel>
                </Tag>
                <Text
                  mb={0}
                  fontSize="1.688rem"
                  textTransform="uppercase"
                  fontWeight={700}
                  data-test-id="event-date"
                >
                  {`${formattedDate}`}
                </Text>
                <Text data-test-id="event-details" mb={0}>
                  {getEventDetailsString(event as EventResponse)}
                </Text>
                <Text data-test-id="location-name" mb={0}>
                  {event.location?.name}
                </Text>
                <Text data-test-id="location-address">{event.location?.formatted_address}</Text>
              </Flex>
              {!isMobile && (
                <>
                  <Spacer />
                  <Flex direction="column" gap="1rem" flexWrap="wrap" alignItems="center">
                    <Flex
                      borderRadius="100%"
                      bgColor="pink.500"
                      w="104px"
                      h="104px"
                      justifyContent="center"
                    >
                      <Text
                        textColor="white"
                        fontSize="1.875rem"
                        py="2rem"
                        px="1.5rem"
                        data-test-id="tickets-sold-capacity"
                      >{`${event.numAttendees}/${event.capacity}`}</Text>
                    </Flex>
                    <Text data-test-id="tickets-capacity-explanation" fontSize="0.75rem">
                      {t('ticketsSoldLabel')}
                    </Text>
                  </Flex>
                </>
              )}
            </Flex>
            <Divider mb={0} color="gray.300" borderBottomWidth="0.063rem" opacity={1} />
            <Flex
              direction={{ base: 'column-reverse', lg: 'row' }}
              gap="0.5rem"
              alignItems={{ lg: 'center' }}
              mt={{ base: '0.75rem', lg: '0.125rem' }}
            >
              <Tabs>
                <Tab px={0} color="pink.500">
                  {t('attendeesTitle')}
                </Tab>
              </Tabs>
              <Spacer />
              <Flex
                gap={{ base: '0.375rem', lg: '0.75rem' }}
                direction={{ base: 'column', lg: 'row' }}
              >
                <Text fontSize="0.875rem" mb={0} pt="0.135rem">
                  {`${t('editText')}`}
                </Text>
                <Button
                  variant="outline"
                  colorScheme="black"
                  size="xs"
                  onClick={onClickCancelClass}
                  disabled={event.status === EVENT_STATUS.CANCELLED}
                  w="fit-content"
                  data-test-id="cancel-button"
                >
                  {t('cancelButton')}
                </Button>
              </Flex>
            </Flex>
            {error && (
              <Text
                textAlign="end"
                textColor="pink.500"
                fontWeight="pink.500"
                data-test-id="error-message"
              >
                {error}
              </Text>
            )}
          </Box>
          <Box bgColor="gray.50" w="100%" h="fit-content" maxH="50vh" overflow="auto">
            <Flex
              mb="3.5rem"
              pt="1rem"
              px={{ base: '1.25rem', lg: '4rem' }}
              gap="1rem"
              direction="column"
              data-test-id="attendees-list"
            >
              {!ticketsLoaded && !isError ? (
                <Spinner
                  thickness="6px"
                  speed="0.65s"
                  emptyColor="gray.100"
                  color="pink.500"
                  h="80px"
                  w="80px"
                  alignSelf="center"
                />
              ) : (
                event.tickets.map((ticket: TicketResponse, index: number) => {
                  return (
                    <EventAttendeeCard
                      ticket={ticket}
                      setQueenToRemove={setQueenToRemove}
                      key={index}
                    />
                  );
                })
              )}
            </Flex>
          </Box>
        </>
      )}
    </>
  );
}
