import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { useTranslations } from 'next-intl';
import { ChangeEvent, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { EVENT_TYPE, LICENSE_TYPE } from '../../constants/enums';
import { useTypedSelector } from '../../hooks/store';
import { useCreateEventMutation, useGetRoutinesByDateQuery } from '../../store/api';
import { IrlRoutine } from '../../types/routines';
import { convertDateToString } from '../../utils/dateTime';
import { serializePlace } from '../../utils/serialize/place';
import { capitalizeFirstLetter } from '../../utils/string';

const resultListStyle = {
  background: 'gray.200',
  position: 'absolute',
  paddingY: 4,
  zIndex: 2,
  width: '100%',
} as const;

const resultListItemStyle = {
  listStyleImage: 'none !important',
  paddingLeft: 0,
  marginBottom: 0,
  marginRight: 4,
  paddingY: 2,
  cursor: 'pointer',
} as const;

const formLabelStyle = {
  marginBottom: '0.125rem',
  fontSize: '0.75rem',
  lineHeight: '0.875rem',
  textColor: 'gray.700',
  fontWeight: 500,
} as const;

const eventTypeLabel = {
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 400,
} as const;

const timeInputStyle = {
  type: 'number',
  border: 1,
  borderColor: 'gray.200',
  bgColor: 'white',
  w: { base: 'full', md: '12.5rem' },
  borderRadius: '0.375rem',
} as const;

interface Props {
  closeModal: () => void;
}

export default function EventCreateForm(props: Props) {
  const { closeModal } = props;

  const { user } = useTypedSelector((state) => state);

  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [classDate, setClassDate] = useState<Date | null>(null);
  const [classTime, setClassTime] = useState<Date | null>(null);
  const [routine, setRoutine] = useState<IrlRoutine | null>(null);
  const [licenseType, setLicenseType] = useState<LICENSE_TYPE>(LICENSE_TYPE.COMMERCIAL);
  const [eventType, setEventType] = useState<EVENT_TYPE | undefined>(undefined);
  const [classLengthHour, setClassLengthHour] = useState<number | undefined>(undefined);
  const [classLengthMins, setClassLengthMins] = useState<number | undefined>(undefined);
  const [placeInputValue, setPlaceInputValue] = useState<string>('');
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [studioName, setStudioName] = useState<string>('');
  const [messageToQueens, setMessageToQueens] = useState<string>('');
  const [formError, setFormError] = useState<string>('');

  const [createEvent] = useCreateEventMutation();

  const [ticketPrice, setTicketPrice] = useState<string>('10');
  const [capacity, setCapacity] = useState<number>(10);

  const t = useTranslations('BossHub.createEventForm');

  const { data, isFetching, isLoading } = useGetRoutinesByDateQuery(
    classDate ? { date: classDate, licenseType: licenseType } : skipToken,
  );

  const dataLoaded = !isFetching && !isLoading && data;

  // Init autocomplete places service
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      debounce: 300, // sets how often the places api is called for predictions (in ms). Increase to help quotas
    });

  const handleSubmit = async (e: any) => {
    // Stops page refreshing on errors
    e.preventDefault();

    setLoading(true);

    if (
      selectedPlace !== null &&
      classDate !== null &&
      classTime !== null &&
      classLengthHour !== undefined &&
      classLengthMins !== undefined &&
      eventType !== undefined &&
      routine !== null &&
      routine?.id !== undefined &&
      eventType !== undefined
    ) {
      const location = Object.assign({}, selectedPlace, { name: studioName });

      const response = await createEvent({
        startDate: convertDateToString(classDate),
        startTime: convertDateToString(classTime),
        durationHours: Number(classLengthHour),
        durationMinutes: Number(classLengthMins),
        routine: { id: routine.id, artist: routine.artist, songName: routine.songName },
        type: eventType,
        licenseType: licenseType,
        location: serializePlace(location),
        ticketPrice: Number(ticketPrice),
        capacity: capacity,
        messageToQueens: messageToQueens,
      });

      if ('data' in response) {
        closeModal();
        toast({
          title: `Event added`,
          status: 'success',
          isClosable: true,
        });
      } else {
        setLoading(false);

        setFormError(t('error.general'));
      }
    } else {
      setLoading(false);
      setFormError(t('error.missingDetails'));
    }
  };

  function onLicenseChange(e: any) {
    setLicenseType(e.target.value);
  }

  function onDateChange(date: Date) {
    setClassDate(date);
  }

  function onSelectEventType(e: EVENT_TYPE) {
    setEventType(e);
    if (e === EVENT_TYPE.CLASS) {
      setClassLengthHour(1);
      setClassLengthMins(0);
    }
    if (e === EVENT_TYPE.WORKSHOP) {
      setClassLengthHour(1);
      setClassLengthMins(30);
    }
  }

  function handleHourInputChange(e: any) {
    setClassLengthHour(e.target.value);
  }

  function handleMinsInputChange(e: any) {
    setClassLengthMins(e.target.value);
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>): void {
    if (selectedPlace) {
      setSelectedPlace(null);
    }
    getPlacePredictions({ input: event.target.value });
    setPlaceInputValue(event.target.value);
  }

  function placeSelected(place: any): void {
    setPlaceInputValue(place.description);

    placesService?.getDetails(place, (details) => {
      if (!details) return;

      setSelectedPlace(details);
      setStudioName(details.name || '');
    });

    getPlacePredictions({ input: '' });
  }

  const formatTicketPrice = (val: string) => `£${val}`;

  function onChangeTicketPrice(val: string) {
    setTicketPrice(val.replace(/^\£/, ''));
  }

  useEffect(() => {
    if (data && data instanceof Array) {
      setRoutine(data[0]);
    }
  }, [data]);

  if (loading) {
    return <Spinner m="auto" mt="6rem" color="pink.500"></Spinner>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap="1.175rem">
        <FormControl isRequired>
          <Select onChange={(e: any) => onLicenseChange(e)} w="40%">
            <option value={LICENSE_TYPE.COMMERCIAL}>
              {capitalizeFirstLetter(LICENSE_TYPE.COMMERCIAL)}
            </option>
            {user.bossQualifications?.heels && (
              <option value={LICENSE_TYPE.HEELS}>
                {capitalizeFirstLetter(LICENSE_TYPE.HEELS)}
              </option>
            )}
          </Select>
        </FormControl>
        <Flex gap="1rem" w={{ base: 'full', md: 'fit-content' }}>
          <FormControl isRequired data-test-id="select-date-form-group">
            <FormLabel data-test-id="select-date-label" sx={formLabelStyle}>
              {t('dateLabel')}
            </FormLabel>
            <Flex
              border="1px"
              borderColor="gray.200"
              borderRadius="0.375rem"
              w="fit-content"
              alignItems="center"
            >
              <DatePicker
                selected={classDate}
                dateFormatCalendar="MMMM"
                onChange={(date: Date) => {
                  onDateChange(date);
                }}
                customInput={
                  <Flex alignItems="center">
                    <Input
                      value={classDate ? moment(classDate).format('D/M/YYYY') : undefined}
                      placeholder="--/--/----"
                      border={0}
                    />
                    <FontAwesomeIcon color="gray.700" icon={faAngleDown} />
                  </Flex>
                }
              />
            </Flex>
          </FormControl>
          <FormControl data-test-id="select-time-form-group" isRequired>
            <FormLabel data-test-id="select-time-label" sx={formLabelStyle}>
              {t('timeLabel')}
            </FormLabel>
            <Flex
              border="1px"
              borderColor="gray.200"
              borderRadius="0.375rem"
              w="fit-content"
              alignItems="center"
              pr="0.5rem"
            >
              <DatePicker
                selected={classTime}
                placeholderText="00:00"
                onChange={(date: Date) => {
                  setClassTime(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm a"
                timeIntervals={5}
              />
            </Flex>
          </FormControl>
        </Flex>
        <Flex bg="gray.50" flexDirection="column" p="1rem" borderRadius="0.25rem">
          <Text data-test-id="routine-label" fontWeight="600">
            {t('routineLabel')}
            <span style={{ fontWeight: '300' }}>{t('routineSelectPrompt')}</span>
          </Text>
          {routine || !dataLoaded ? (
            <Text
              data-test-id="routine-details"
              px="0.75rem"
              py="0.175rem"
              w="fit-content"
              borderRadius="0.375rem"
              bg="pink.500"
              textColor="white"
              fontWeight="500"
              fontSize="1rem"
              lineHeight="1.5rem"
            >
              {routine ? `${routine.artist} - ${routine.songName}` : t('routineUnset')}
            </Text>
          ) : (
            <></>
          )}
          {dataLoaded && !routine && <Text color="pink.500">{t('noRoutinesErrorMessage')}</Text>}
          <FormControl data-test-id="select-event-type-form-group" isRequired>
            <FormLabel data-test-id="select-event-type-label" fontWeight="600">
              {t('selectEventLabel')}
            </FormLabel>
            <RadioGroup
              data-test-id="event-type-options"
              onChange={(e: EVENT_TYPE) => onSelectEventType(e)}
              value={eventType}
            >
              <Stack direction={{ base: 'column', md: 'row' }} gap="0.25rem">
                <Box
                  border="1px"
                  borderColor={eventType === EVENT_TYPE.CLASS ? 'gray.600' : 'gray.200'}
                  bgColor="white"
                  borderRadius="0.75rem"
                  py="0.75rem"
                  px="0.5rem"
                  w="fit-content"
                >
                  <Radio colorScheme="pink" value={EVENT_TYPE.CLASS}>
                    <Text data-test-id="class-option-label" sx={eventTypeLabel} pt="0.125rem">
                      {t('eventTypeSection.classOption')}
                    </Text>
                  </Radio>
                </Box>
                <Box
                  border="1px"
                  borderColor={eventType === EVENT_TYPE.WORKSHOP ? 'gray.600' : 'gray.200'}
                  bgColor="white"
                  borderRadius="0.75rem"
                  py="0.75rem"
                  px="0.5rem"
                  w="fit-content"
                >
                  <Radio colorScheme="pink" value={EVENT_TYPE.WORKSHOP}>
                    <Text data-test-id="workshop-option-label" sx={eventTypeLabel} pt="0.125rem">
                      {t('eventTypeSection.workshopOption')}
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Flex
            gap="0.75rem"
            borderRadius="0.5rem"
            border="0.063rem"
            borderColor="gray.200"
            mt="1rem"
            w={{ base: '75%', md: 'fit-content' }}
          >
            <FormControl data-test-id="class-time-hour-form-group" isRequired>
              <FormLabel data-test-id="class-time-hour-label" sx={formLabelStyle}>
                {t('eventTypeSection.hourLabel')}
              </FormLabel>
              <Input
                value={classLengthHour}
                onChange={(e) => handleHourInputChange(e)}
                placeholder={t('eventTypeSection.timePlaceholder')}
                sx={timeInputStyle}
              />
            </FormControl>
            <FormControl data-test-id="class-time-mins-form-group" isRequired>
              <FormLabel data-test-id="class-time-mins-label" sx={formLabelStyle}>
                {t('eventTypeSection.minLabel')}
              </FormLabel>
              <Input
                value={classLengthMins}
                onChange={(e) => handleMinsInputChange(e)}
                placeholder={t('eventTypeSection.timePlaceholder')}
                sx={timeInputStyle}
              />
            </FormControl>
          </Flex>
        </Flex>
        <FormControl data-test-id="studio-location-form-group" isRequired>
          <FormLabel data-test-id="location-label" sx={formLabelStyle}>
            {t('locationLabel')}
          </FormLabel>
          <Input
            type="text"
            fontSize={{ base: 'sm', md: 'md' }}
            placeholder={t('locationPlaceholder')}
            value={placeInputValue}
            onChange={(event) => onInputChange(event)}
          />
          {!isPlacePredictionsLoading && placePredictions.length > 0 && (
            <List sx={{ ...resultListStyle }}>
              {placePredictions.map((place: any, index) => (
                <ListItem
                  sx={resultListItemStyle}
                  key={`place_${index}`}
                  onClick={() => placeSelected(place)}
                >
                  {place.description}
                </ListItem>
              ))}
            </List>
          )}
        </FormControl>
        <FormControl data-test-id="studio-name-label" isRequired>
          <FormLabel sx={formLabelStyle}>Studio Name</FormLabel>
          <Input
            type="text"
            placeholder="Studio"
            value={studioName}
            onChange={(e) => setStudioName(e.target.value)}
          />
        </FormControl>
        <Flex direction="row" gap="1.5rem" w={{ base: 'full', md: '50%' }}>
          <FormControl data-test-id="ticket-price-form-group" isRequired>
            <FormLabel data-test-id="ticket-price-label" sx={formLabelStyle}>
              {t('ticketPriceLabel')}
            </FormLabel>
            <NumberInput
              value={formatTicketPrice(ticketPrice)}
              onChange={(val) => onChangeTicketPrice(val)}
            >
              <NumberInputField pattern="(£)?[0-9]*(.[0-9]+)?" />
              <NumberInputStepper textColor="gray.700">
                <NumberIncrementStepper>
                  <FontAwesomeIcon icon={faCaretUp} />
                </NumberIncrementStepper>
                <NumberDecrementStepper>
                  <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                </NumberDecrementStepper>
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl data-test-id="capacity-form-group" isRequired>
            <FormLabel data-test-id="capacity-label" sx={formLabelStyle}>
              {t('capacityLabel')}
            </FormLabel>
            <NumberInput value={capacity} onChange={(val) => setCapacity(Number(val))}>
              <NumberInputField />
              <NumberInputStepper textColor="gray.700">
                <NumberIncrementStepper>
                  <FontAwesomeIcon icon={faCaretUp} />
                </NumberIncrementStepper>
                <NumberDecrementStepper>
                  <FontAwesomeIcon icon={faCaretDown} />
                </NumberDecrementStepper>
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Flex>
        <FormControl data-test-id="message-to-queens-form-group">
          <FormLabel data-test-id="message-to-queens-label" sx={eventTypeLabel}>
            {t('messageToQueensLabel')}
          </FormLabel>
          <Textarea
            placeholder={t('messageToQueensPlaceholder')}
            onChange={(e) => setMessageToQueens(e.target.value)}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            data-test-id="submit-button"
            size="md"
            colorScheme="pink"
            type="submit"
            w={{ base: 'full', md: 'fit-content' }}
          >
            {t('publishClassButton')}
          </Button>
        </Flex>
      </Flex>
      {formError && (
        <Text id="error" color="pink.500" fontWeight="600">
          {formError}
        </Text>
      )}
    </form>
  );
}
