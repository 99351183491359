import { Alert, ChakraProvider, Text } from '@chakra-ui/react';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/nextjs';
import { Analytics, getAnalytics, setUserId } from 'firebase/analytics';
import { NextIntlProvider } from 'next-intl';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import CookieBanner from '../components/banners/CookieBanner';
import Layout from '../components/layout/Layout';
import AuthModal from '../components/modals/AuthModal';
import CookieModal from '../components/modals/CookieModal';
import EmailAttendeesModal from '../components/modals/EmailAttendeesModal';
import EventBookModal from '../components/modals/EventBookModal';
import EventCreateModal from '../components/modals/EventCreateModal';
import EventViewModal from '../components/modals/EventViewModal';
import ProfileModal from '../components/modals/ProfileModal';
import SubscribeModal from '../components/modals/SubscribeModal';
import VideoModal from '../components/modals/VideoModal';
import { firebaseApp, firebaseAuth } from '../config/firebase';
import storyblok from '../config/storyblok';
import { AUTH_MODAL_PAGES, ENVIRONMENTS } from '../constants/enums';
import {
  APP_LOADED,
  AUTH_MODAL_OPENED,
  AUTH_MODAL_SIGN_UP_VIEWED,
  GET_USER_ERROR,
} from '../constants/events';
import useIsBackstageRoute from '../hooks/isBackstageRoute';
import { useAppDispatch, useTypedSelector } from '../hooks/store';
import { api, useGetUserQuery } from '../store/api';
import { setAuthModalViewed, updateCookies } from '../store/cookiesSlice';
import { openAuthModal } from '../store/modalsSlice';
import store, { wrapper } from '../store/store';
import { clearUserSlice, setUserAuth, setUserAuthLoading } from '../store/userSlice';
import '../styles/datepicker.css';
import '../styles/firebaseui.css';
import '../styles/globals.css';
import '../styles/slick.css';
import theme from '../styles/theme';
import { handleFirstTab } from '../utils/accessibility';
import { addDaysToToday } from '../utils/dateTime';
import { analyticsEvent } from '../utils/logEvent';

config.autoAddCss = false;

// Init storyblok
storyblok;

interface Props extends AppProps {
  includeLayout?: boolean; // used in cypress testing
}

export const mixpanelEvent = (eventName: any, props?: any) => {
  try {
    if ((window as any).mixpanel) {
      (window as any).mixpanel.track(eventName, props);
    }
  } catch (e) {
    console.log(e);
  }
};

export const setMixpanelIdentity = (userId: string) => {
  try {
    if ((window as any).mixpanel) {
      (window as any).mixpanel.identify(userId);
    }
  } catch (e) {
    console.log(e);
  }
};

function MyApp({ Component, pageProps, includeLayout = true }: Props) {
  const [cookies, setCookie] = useCookies(['authModalViewed', 'cookie_settings']);
  const [showUserError, setShowUserError] = useState(false);
  const isBackstageRoute = useIsBackstageRoute();
  const dispatch: any = useAppDispatch();

  const { user, cookiesState } = useTypedSelector((state) => state);

  // Call getUser to populate User db data in User state.
  // Skip if user is unauthenticated or User db data is already loaded
  const { data, error, isError } = useGetUserQuery(
    user.authLoading || user.loading || user.id || !user.firebaseUid ? skipToken : undefined,
  );

  useEffect(() => {
    if ((window as any).Cypress) {
      dispatch(
        updateCookies({
          performanceAccepted: true,
          marketingAccepted: true,
          analyticsAccepted: true,
          settingsSet: true,
        }),
      );
    } else if (cookies.cookie_settings) {
      dispatch(
        updateCookies({
          performanceAccepted: cookies.cookie_settings.performance,
          marketingAccepted: cookies.cookie_settings.marketing,
          analyticsAccepted: cookies.cookie_settings.analytics,
          settingsSet: true,
        }),
      );
    }

    if (cookies.authModalViewed) {
      dispatch(setAuthModalViewed());
    }
  }, [cookies, dispatch]);

  useEffect(() => {
    // Init firebase analytics
    let firebaseAnalytics: Analytics;

    if ((window as any).Cypress) {
      // Make store available when running in Cypress
      (window as any).store = store;
    } else {
      if (cookiesState.analyticsAccepted === true) {
        firebaseAnalytics = getAnalytics(firebaseApp);
      }
      analyticsEvent(APP_LOADED);
    }

    // Add listener for auth token changes to update User state with auth values, also unblocks useGetUserQuery above.
    // Required for restoring user state following app reload or revisiting site.
    // We handle this logic separately for sign up to prevent a race condition.
    firebaseAuth.onIdTokenChanged(async function (firebaseUser) {
      if (firebaseUser && user.authLoading) {
        const token = await firebaseUser.getIdToken();
        if (token) {
          if (cookiesState.analyticsAccepted === true) {
            setUserId(firebaseAnalytics, firebaseUser.uid);
          }
          await dispatch(
            setUserAuth({ uid: firebaseUser.uid, token, email: firebaseUser.email || '' }),
          );
        }
      }
      if (!firebaseUser) {
        dispatch(setUserAuthLoading(false));
      }
    });

    // Add listener for user tabbing, to turn on accessibility styles
    window.addEventListener('keydown', handleFirstTab);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isError) {
      // Call to get user failed, reset firebase auth and state
      dispatch(clearUserSlice());
      dispatch(api.util.resetApiState());
      firebaseAuth.signOut();
      analyticsEvent(GET_USER_ERROR);
      Sentry.captureException(error);
      setShowUserError(true);
    }
  }, [isError, error, dispatch]);

  useEffect(() => {
    async function openModal() {
      analyticsEvent(AUTH_MODAL_OPENED, { origin_component: 'first_view_popup' });
      analyticsEvent(AUTH_MODAL_SIGN_UP_VIEWED, { origin_component: 'first_view_popup' });
      await dispatch(openAuthModal(AUTH_MODAL_PAGES.SIGN_UP));
    }

    // Show auth modal on backstage if not seen within 1 week
    if (
      isBackstageRoute &&
      !user.loading &&
      !user.authLoading &&
      !user.firebaseUid &&
      !cookiesState.authModalViewed &&
      !(window as any).Cypress
    ) {
      // Set authModalViewed cookie with expiry of 1 week from now
      const expires = addDaysToToday(7);
      if (cookiesState.performanceAccepted) {
        setCookie('authModalViewed', true, { path: '/', expires: expires });
      }
      dispatch(setAuthModalViewed());

      // Trigger auth modal
      openModal();
    }
  }, [
    user.firebaseUid,
    user.loading,
    user.authLoading,
    isBackstageRoute,
    cookiesState.authModalViewed,
    setCookie,
    dispatch,
    cookiesState.performanceAccepted,
  ]);

  useEffect(() => {
    (window as any)._mfq = (window as any)._mfq || [];
    (function () {
      var mf = document.createElement('script');
      mf.type = 'text/javascript';
      mf.defer = true;
      mf.src = '//cdn.mouseflow.com/projects/dc128488-f37a-482d-9662-74fd2d6bb372.js';
      document.getElementsByTagName('head')[0].appendChild(mf);
    })();
  });

  return (
    <NextIntlProvider messages={pageProps.messages} timeZone="Europe/London" locale="en">
      <CookiesProvider>
        <ChakraProvider theme={theme}>
          {/* TODO: improve duplicate scripts loading due to two google packages being used, and requirement to load script on parent page */}
          <Script
            id="google_script"
            strategy="beforeInteractive"
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}
          />
          <Script
            id="eventbrite_script"
            strategy="beforeInteractive"
            src={`https://www.eventbrite.com/static/widgets/eb_widgets.js`}
          />
          {process.env.NEXT_PUBLIC_ENV === ENVIRONMENTS.PRODUCTION &&
            typeof window != 'undefined' &&
            !(window as any).mixpanel && (
              <Script
                id="mixpanel-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                  (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
                  for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
                  MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
                  mixpanel.init('786959d37eb95896d3abb8588e0c8863', {debug: true});
                `,
                }}
              />
            )}
          <AuthModal />
          <SubscribeModal />
          <CookieModal />
          <EventBookModal />
          <EventCreateModal />
          <EventViewModal />
          <EmailAttendeesModal />
          <ProfileModal />
          <VideoModal />
          {!includeLayout && <Component {...pageProps} />}
          {includeLayout && (
            <Layout>
              <>
                {showUserError && !user.id && (
                  <Alert background="pink.500">
                    <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                    <Text ml={4}>
                      {/* TODO: improve when using languages - useTranslations can't be used in _app.tsx */}
                      Oops...there seems to be an issue with your account. Try logging in later or
                      get in touch.
                    </Text>
                  </Alert>
                )}
                <Component {...pageProps} />
              </>
            </Layout>
          )}
          {!cookiesState.settingsSet && <CookieBanner />}
        </ChakraProvider>
      </CookiesProvider>
    </NextIntlProvider>
  );
}

export default wrapper.withRedux(MyApp);
