import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Textarea,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { EVENT_BOOK_PAGES } from '../../constants/enums';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { clearEventSlice } from '../../store/eventSlice';
import { clearModalsSlice } from '../../store/modalsSlice';

const eventTypeLabel = {
  fontSize: '1rem',
  lineHeight: '1.25rem',
  fontWeight: 400,
  textAlign: { lg: 'center' },
} as const;

export default function EmailAttendeesModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals } = useTypedSelector((state) => state);

  const [messageToQueens, setMessageToQueens] = useState<string>('');

  const t = useTranslations('BossHub.emailAttendees');

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (modals.emailAttendeesModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.emailAttendeesModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    // analyticsEvent(VIDEO_MODAL_CLOSED);
    dispatch(clearModalsSlice());
    dispatch(clearEventSlice());
  }

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size={isMobile ? 'full' : '4xl'}>
      <ModalOverlay />
      <ModalContent
        data-test-id="email-attendees-modal-content"
        px={{ base: '2rem', lg: '12rem' }}
        py={{ base: '12rem', lg: '14rem' }}
        borderRadius={{
          base: 0,
          lg: modals.bookModalPage === EVENT_BOOK_PAGES.DETAILS ? '1.375rem' : '0.75rem',
        }}
        maxH="full"
        overflow="hidden"
      >
        <ModalCloseButton
          top="1rem"
          right="1rem"
          zIndex={2}
          borderRadius="8rem"
          bgColor="#0000000F"
          textColor="black"
          data-test-id="book-event-modal-close-button"
        />
        <Flex overflow="auto" w="100%" h="100%" alignItems="center" direction="column" gap="0.5rem">
          <Heading size="md" mb={0}>
            {t('title')}
          </Heading>
          <FormControl data-test-id="message-to-queens-form-group">
            <FormLabel data-test-id="message-to-queens-label" sx={eventTypeLabel}>
              {t('messageToQueensLabel')}
            </FormLabel>
            <Textarea
              placeholder={t('messageToQueensPlaceholder')}
              onChange={(e) => setMessageToQueens(e.target.value)}
            />
          </FormControl>
          <Button colorScheme="pink" w="fit-content" size="md" alignSelf="flex-end">
            {t('button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
